import {
  getDaysTillGoalDate,
  getDefaultGoalPaceKg,
  getDefaultGoalPaceLbs,
  getGolaPaceKgValues,
  getGolaPaceLbsValues,
  getLocalizedDateLabel,
} from "../helpers"

export default (getView) =>
  ({ nextStepPath, formFields, redirectIfWeightGainUrl }) => ({
    ...getView({
      nextStepPath,
      formFields,
      redirectIfWeightGainUrl,
    }),

    goalPaceKgValuesMap: getGolaPaceKgValues(),
    goalPaceLbsValuesMap: getGolaPaceLbsValues(),
    defaultGoalPaceKg: getDefaultGoalPaceKg(),
    defaultGoalPaceLbs: getDefaultGoalPaceLbs(),
    initContentHidden: true,

    init() {
      this.commonInit()

      this.formValues.goalPaceKg = this.store.values.goalPaceKg || this.defaultGoalPaceKg
      this.formValues.goalPaceLbs = this.store.values.goalPaceLbs || this.defaultGoalPaceLbs
    },

    handleInput($event) {
      this.formValues.goalPaceKg = this.goalPaceKgValuesMap[Number($event.target.value)]
      this.formValues.goalPaceLbs = this.goalPaceLbsValuesMap[Number($event.target.value)]
    },

    getGoalDateLabel(isIncludeYear) {
      const isMetric = this.isMetric()
      // Use already stored used data
      const { weightKg, weightLbs, targetWeightKg, targetWeightLbs } = this.store.values
      // Use dynamic form data for UI
      const { goalPaceKg, goalPaceLbs } = this.formValues

      const daysTillGoalDate = getDaysTillGoalDate(
        {
          weightKg,
          weightLbs,
          targetWeightKg,
          targetWeightLbs,
          goalPaceKg,
          goalPaceLbs,
        },
        isMetric
      )

      const goalDate = new Date()
      goalDate.setDate(goalDate.getDate() + daysTillGoalDate)

      return getLocalizedDateLabel(goalDate, isIncludeYear)
    },
  })
