import {
  getBmi,
  getDaysTillGoalDate,
  getFasterProgressDaysDiff,
  getLocalizedDateLabel,
} from "../helpers"
import { createPrimerCheckout, createRedirectCheckout } from "../payments"

export default ({ nextStepPath, paymentMethods, isAbandonedBasket } = {}) => ({
  paymentMethods: [],
  offers: [],
  activeOffer: null,
  showSpinner: false,
  showFetchError: false,
  paymentError: false,

  init() {
    const searchParams = new URLSearchParams(window.location.search)
    const paymentError = searchParams.get("error")
    if (paymentError) {
      this.paymentError = paymentError
    }

    this.store = Alpine.store("data")

    const offers = pipelineConfig.offers.sort(
      (a, b) => a.customData.sequence - b.customData.sequence
    )
    const storedOffer = this.store.values.activeOffer
    const selectedOffer =
      storedOffer || offers.find((offer) => offer.customData.default_select === "true")

    this.offers = offers
    this.activeOffer = selectedOffer || offers[0]

    if (paymentMethods) {
      this.paymentMethods = paymentMethods.split(",")
      this.trackVisitCheckout()
    }

    if (isAbandonedBasket) {
      this.updateOrderUtms()
    }
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  getBmi() {
    return getBmi(this.store.values, this.isMetric()).toFixed(2)
  },

  getTargetBmi() {
    const { heightCm, heightFt, heightIn, targetWeightKg, targetWeightLbs } = this.store.values

    const values = {
      heightCm,
      heightFt,
      heightIn,
      weightKg: targetWeightKg,
      weightLbs: targetWeightLbs,
    }

    return getBmi(values, this.isMetric()).toFixed(2)
  },

  getGoalDateLabel() {
    const isMetric = this.isMetric()
    // Use already stored used data
    const { weightKg, weightLbs, targetWeightKg, targetWeightLbs, goalPaceKg, goalPaceLbs } =
      this.store.values

    const daysTillGoalDate = getDaysTillGoalDate(
      {
        weightKg,
        weightLbs,
        targetWeightKg,
        targetWeightLbs,
        goalPaceKg,
        goalPaceLbs,
      },
      isMetric
    )

    const fasterProgressDaysDiff = getFasterProgressDaysDiff(this.store.values, isMetric)

    const goalDate = new Date()
    goalDate.setDate(goalDate.getDate() + daysTillGoalDate - fasterProgressDaysDiff)

    return getLocalizedDateLabel(goalDate, true)
  },

  isOfferTrial(offer) {
    return !!offer.customData.trial_price
  },

  isOfferActive(offer) {
    return offer.brandOfferId === this.activeOffer.brandOfferId
  },

  isBestDeal(offer) {
    const isTrial = this.isOfferTrial(offer)
    const periodPriceKey = isTrial ? "trial_period_price" : "period_price"
    const periodPrice = parseFloat(offer.customData[periodPriceKey])
    const othersOffersPeriodPrices = this.offers.map((o) => {
      const isOtherOfferTrial = this.isOfferTrial(o)
      const otherOfferPeriodPriceKey = isOtherOfferTrial ? "trial_period_price" : "period_price"

      return parseFloat(o.customData[otherOfferPeriodPriceKey])
    })
    const min = Math.min(...othersOffersPeriodPrices)

    // Cosider that biggest value should not have duplicates
    return (
      periodPrice === min &&
      othersOffersPeriodPrices.lastIndexOf(periodPrice) ===
        othersOffersPeriodPrices.indexOf(periodPrice)
    )
  },

  getOfferOldPrice(offer) {
    const isTrial = this.isOfferTrial(offer)

    if (isTrial) {
      if (!offer.customData.total_price) return false

      return offer.customData.symbol + offer.customData.total_price
    } else {
      if (!offer.customData.total_price_without_discount) return false

      return offer.customData.symbol + offer.customData.total_price_without_discount
    }
  },

  getOfferActualPrice(offer) {
    const isTrial = this.isOfferTrial(offer)

    if (isTrial) return offer.customData.symbol + offer.customData.trial_price

    return offer.customData.symbol + offer.customData.total_price
  },

  getOfferPeriodPrice(offer) {
    const isTrial = this.isOfferTrial(offer)

    if (isTrial) return offer.customData.symbol + offer.customData.trial_period_price

    return offer.customData.symbol + offer.customData.period_price
  },

  getFormattedPriceWithoutDiscount() {
    const isTrial = this.isOfferTrial(this.activeOffer)

    const price = isTrial
      ? this.activeOffer.customData.total_price
      : this.activeOffer.customData.total_price_without_discount

    return this.activeOffer.customData.symbol + price
  },

  getFormattedDiscount() {
    return this.activeOffer.customData.symbol + this.activeOffer.customData.total_price_discount
  },

  getFormattedTotal() {
    const isTrial = this.isOfferTrial(this.activeOffer)

    const price = isTrial
      ? this.activeOffer.customData.trial_price
      : this.activeOffer.customData.total_price

    return this.activeOffer.customData.symbol + price
  },

  getFormattedTotalWithoutTrial() {
    return this.activeOffer.customData.symbol + this.activeOffer.customData.total_price
  },

  scrollToElement(id) {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth" })
  },

  selectOffer(offer) {
    window.analytics.track(window.getEventName("Plan Selected"), {
      title: offer.customData.title,
    })

    this.activeOffer = offer
    this.store.values.activeOffer = offer
  },

  createRedirectCheckout(paymode) {
    createRedirectCheckout(paymode)
  },

  async submitOffer() {
    this.showSpinner = true

    try {
      window.analytics.track(window.getEventName("Get Plan Clicked"), {
        nextStepPath,
      })

      await createPrimerCheckout()

      window.location = nextStepPath
      this.showSpinner = false
    } catch (error) {
      console.error("Order submit failed:", error)
      this.showSpinner = false
    }
  },

  async completePayment() {
    this.store.values.completedAt = new Date()

    await this.store.push()
  },

  async trackVisitCheckout() {
    this.store.values.status = "VISIT_CHECKOUT"
    await this.store.push()
  },

  async updateOrderUtms() {
    await this.store.push()
  },
})
