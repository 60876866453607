import { getDaysTillGoalDate, getInputDateFormatted } from "../helpers"

export default (getView) =>
  ({ nextStepPath, formFields, redirectIfWeightGainUrl }) => ({
    ...getView({
      nextStepPath,
      formFields,
      redirectIfWeightGainUrl,
    }),

    minDate: null,
    maxDate: null,
    minDateError: false,
    maxDateError: false,
    initContentHidden: true,

    init() {
      this.commonInit()

      this.minDate = this.getTodayDateFormatted()
      this.maxDate = this.getGoalDateFormatted()
    },

    getTodayDateFormatted() {
      const today = new Date()

      return getInputDateFormatted(today)
    },

    getGoalDateFormatted() {
      const today = new Date()
      const daysTillGoalDate = this.getDaysTillGoalDate()
      const goalDate = new Date()
      goalDate.setDate(today.getDate() + daysTillGoalDate - 1)

      return getInputDateFormatted(goalDate)
    },

    handleDateInput($event) {
      this.formValues["importantEventDate"] = $event.target.value && $event.target.value.trim()
      this.errors["importantEventDate"] = null

      this.setSubmitAllowed(this.formValues, formFields, true)
    },

    handleSkip(nextStepPath) {
      this.formValues["importantEventDate"] = null
      this.errors["importantEventDate"] = null
      this.isSubmitAllowed = true
      this.storeViewData(null)
      this.store.values = { ...this.store.values, ...this.formValues }

      window.location = nextStepPath
    },

    getDaysTillGoalDate() {
      const isMetric = this.isMetric()

      return getDaysTillGoalDate(this.store.values, isMetric)
    },

    setSubmitAllowed(formValues, formFields, isShowUiValidation) {
      this.validateRequired(formValues, formFields)

      this.validateDateRange(isShowUiValidation)
    },

    // Though the min and max values for HTML date picker are set we still need this manual
    // validation, as some browsers (Safari iOS) do not support these HTML attributes
    validateDateRange(isShowUiValidation) {
      const importantEventDate = this.formValues["importantEventDate"]

      if (!importantEventDate) return

      const daysTillGoalDate = this.getDaysTillGoalDate()

      const today = new Date()

      const importantEventDateObj = new Date(importantEventDate)

      const maxDate = new Date()
      maxDate.setDate(today.getDate() + daysTillGoalDate - 1)

      const minDateError = importantEventDateObj < today
      const maxDateError = importantEventDateObj > maxDate

      // Prevent immediate UI validation on init
      if (isShowUiValidation) {
        this.minDateError = minDateError
        this.maxDateError = !minDateError && maxDateError
      }

      this.isSubmitAllowed = !minDateError && !maxDateError
    },
  })
