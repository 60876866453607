import { createPrimerCheckout } from "../payments"
const { Primer } = window

const PRIMER_LOCALES = [
  "de",
  "el",
  "en",
  "es",
  "fr",
  "pl",
  "pt",
  "bg",
  "hu",
  "ro",
  "ru",
  "sk",
  "nl",
]

export default () => ({
  init() {
    this.store = Alpine.store("data")

    function observeElementInsertion(selector) {
      if (!window.MutationObserver) {
        console.error("MutationObserver is not supported in this browser.")
        return
      }

      // Callback function for when a mutation occurs
      const observerCallback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            // Check if the element now exists in the DOM
            const element = document.querySelector(selector)
            if (element) {
              // Once found log event to segment and disconnect observer
              window.analytics.track(window.getEventName("Primer Checkout Loaded Confirmation"))
              observer.disconnect()
              break
            }
          }
        }
      }

      // Create a MutationObserver with the callback function
      const observer = new MutationObserver(observerCallback)

      // Start observing the document's body for added nodes
      observer.observe(document.body, { childList: true, subtree: true })
    }

    window.addEventListener("load", async () => {
      const primerCheckout = await this.getPrimerCheckout()

      const primerCheckoutCardNumberId = "#primer-checkout-card-number-field"
      observeElementInsertion(primerCheckoutCardNumberId)

      if (!primerCheckout) {
        this.displayErrorMessage()
        return
      }

      // Display checkout
      const primerUniversalCheckout = await this.renderPrimer(primerCheckout, this.store)

      // Update pipeline
      await this.initiateCheckout(primerCheckout.invoice.identifier, primerCheckout)

      // If primer doesn't support locale add from translations
      if (!this.isPrimerLocale(pipelineConfig.language)) {
        this.patchTranslations(primerUniversalCheckout)
      } else {
        this.applyCustomSubmitButton(primerUniversalCheckout)
      }

      // Tracking for form submit (different from Pay button click)
      document.getElementById("primer-checkout-card-form").addEventListener("submit", (event) => {
        this.handleSubmitEvent()
      })
    })
  },

  async initiateCheckout(invoiceIdentifier, checkout) {
    this.store.values.checkout = checkout
    this.store.values.invoiceIdentifier = invoiceIdentifier
    this.store.values.initiateCheckoutAt = new Date()
    this.store.values.visitCheckoutAt = new Date()
    this.store.values.preloaded = false

    await this.store.push()
  },

  async getPrimerCheckout() {
    if (this.store.values?.preloaded && this.store.values?.checkout) {
      return this.store.values?.checkout
    }

    try {
      return await createPrimerCheckout()
    } catch (e) {
      console.error(e)
      this.displayErrorMessage()
      return
    }
  },

  // Loads a Primer Checkout
  async renderPrimer(primerCheckout, store) {
    try {
      // Trigger analytics event and if fails so moves on
      try {
        await window.analytics.track(window.getEventName("Primer Checkout Requested"))
      } catch (e) {
        console.log("Segment: Primer Checkout Requested failed", e)
      }

      const options = this.getPrimerOptions(primerCheckout, store)
      const primerUniversalCheckout = await Primer.showUniversalCheckout(
        primerCheckout.invoice.session_identifier,
        options
      )

      window.analytics.track(window.getEventName("Primer Checkout Loaded"))

      return primerUniversalCheckout
    } catch (e) {
      console.log("Primer: Loading showCheckout failed", e)
      this.displayErrorMessage()
      return
    }
  },

  // Displays error message with reload button that refresh the page
  displayErrorMessage() {
    const offlineBlock = window.document.getElementById("offline-msg")
    offlineBlock.style.display = "block"
  },

  // Checks for the compability with locale
  isPrimerLocale(locale) {
    return PRIMER_LOCALES.includes(locale || "en")
  },

  // Primer Checkout options - config, styles, buttons, texts etc.
  getPrimerOptions(primerCheckout, store) {
    const paymentHost = `${pipelineConfig.productApiUrl}`
    const markPaymentAsFailed = this.markPaymentAsFailed.bind(this)

    const options = {
      container: "#checkout-container",
      locale: pipelineConfig.language || "en",
      vault: {
        visible: false,
      },
      successScreen: false,
      errorMessage: false,
      async onCheckoutComplete({ payment }) {
        await window.analytics.track(window.getEventName("Primer Responded Success"))

        window.location = `${paymentHost}/pay/primer/post-payment/${primerCheckout.invoice.id}`
      },

      async onCheckoutFail(error, { payment }) {
        await markPaymentAsFailed()
        await window.analytics.track(window.getEventName("Primer Responded Fail"))

        window.location = `${paymentHost}/pay/primer/post-payment/${primerCheckout.invoice.id}`
      },
      onPaymentMethodAction: (action, data) => {
        const eventKey = ("payment-method-selected-" + data.paymentMethodType).toLowerCase()
        window.analytics.track(window.segmentEventKeys[eventKey] ?? eventKey)
      },
      submitButton: {
        useBuiltInButton: this.isPrimerLocale(pipelineConfig.language),
        onVisible: (isVisible) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isVisible) {
            submitButton.style.display = "flex"
          } else {
            submitButton.style.display = "none"
          }
        },
        onDisable: (isDisabled) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isDisabled) {
            submitButton.disabled = true
          } else {
            submitButton.disabled = false
          }
        },
        onLoading: (isLoading) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isLoading) {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "block"
          } else {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "none"
          }
        },
      },
      card: {
        cardholderName: {
          placeholder: "",
        },
      },
      paypal: {
        buttonColor: "gold",
        buttonShape: "rect",
        paymentFlow: "PREFER_VAULT",
      },
      googlePay: {
        captureBillingAddress: true,
      },
      applePay: {
        captureBillingAddress: true,
      },
      style: {
        formSpacings: {
          betweenLabelAndInput: "12px",
          betweenInputs: "16px",
        },
        inputLabel: {
          fontFamily: "Avenir, Arial, sans-serif",
          fontSize: "12px",
          lineHeight: "normal",
          fontWeight: "normal",
          color: "#212121",
        },
        input: {
          base: {
            background: "#fff",
            color: "#35383C",
          },
        },
        inputErrorText: {
          fontSize: "14px",
          fontWeight: "400",
        },
        submitButton: {
          base: {
            fontFamily: "JosefinSans, sans-serif",
            color: "#fff",
            background: "#46A477",
            fontSize: "20px",
            fontWeight: "bold",
            borderRadius: "6px",
          },
        },
      },
    }

    return options
  },

  // Patch the primer translations with ours
  patchTranslations(primerUniversalCheckout) {
    const applyCustomLabel = (field) => {
      // Remove default primer label
      const currentField = window.document.getElementById(field.id)
      currentField.getElementsByClassName("PrimerCheckout__label")[0].style.display = "none"

      // Create ours
      const newField = window.document.createElement("div")
      newField.dataset.testid = "CreditCardForm.inputLabel"
      newField.classList.add("primer-input-label")
      newField.classList.add("PrimerCheckout__label")
      newField.innerHTML = field.translation

      // Display our label
      currentField.insertBefore(newField, currentField.firstChild)
    }

    const applyCustomErrorMessage = (field) => {
      const currentField = document.getElementById(field.id)
      const targetNode = currentField.getElementsByTagName("div")[0]

      // Mutation config
      const config = { childList: true }

      // Callback function executes on dom changes
      const callback = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            // Apply our custom translation
            if (targetNode.innerHTML !== field.translation && targetNode.innerHTML !== "") {
              targetNode.innerHTML = field.translation
            }
          }
        }
      }
      // Create/Observe the node mutations
      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
    }

    const primerFields = [
      {
        labelField: {
          id: "primer-checkout-card-number-field",
          translation: primerTranslations.cardnumber_label,
        },
        errorField: {
          id: "primer-checkout-card-number",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-card-expirydate-field",
          translation: primerTranslations.expire_label,
        },
        errorField: {
          id: "primer-checkout-card-expirydate-error",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-card-cvv-field",
          translation: primerTranslations.cvc_label,
        },
        errorField: {
          id: "primer-checkout-card-cvv-error",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-cardholder-name-field",
          translation: primerTranslations.username_label,
        },
        errorField: {
          id: "primer-checkout-cardholder-name-error",
          translation: primerTranslations.error.empty_field,
        },
      },
    ]

    primerFields.forEach((field) => {
      applyCustomLabel(field.labelField)
      applyCustomErrorMessage(field.errorField)
    })

    this.applyCustomSubmitButton(primerUniversalCheckout)
  },

  applyCustomSubmitButton(primerUniversalCheckout) {
    // Hide primer original button
    const primerSubmitButton = window.document.getElementById(
      "primer-checkout-submit-button-container"
    )
    primerSubmitButton.style.display = "none"

    const primerWindow = window.document.getElementById("primer-checkout")
    const buttonElement = window.document.createElement("button")
    const laoderElement = window.document.createElement("div")
    const spanElement = window.document.createElement("span")

    // Apply button params
    buttonElement.id = "submit-button"
    buttonElement.className = "submit-button"
    buttonElement.name = "submit"
    buttonElement.type = "button"
    buttonElement.addEventListener(
      "click",
      () => {
        this.handleSubmitEvent()
        primerUniversalCheckout.submit()
      },
      false
    )
    // Apply loader params
    laoderElement.className = "button-loader"
    // Apply span params
    spanElement.className = "button-text mr-2"
    spanElement.innerHTML = primerTranslations.button

    buttonElement.appendChild(spanElement)
    buttonElement.appendChild(laoderElement)
    primerWindow.appendChild(buttonElement)
  },

  async markPaymentAsFailed() {
    this.store.values.failedPayment = true

    await this.store.push()
  },
  handleSubmitEvent() {
    window.analytics.track(window.getEventName("Credit Card Submitted"))

    setTimeout(function () {
      if (
        document.querySelectorAll(".PrimerCheckout__helperText--error.PrimerCheckout--entered")
          .length > 0
      ) {
        window.analytics.track(window.getEventName("Invalid Form Submitted"))
      }
    }, 1500)
  },
})
