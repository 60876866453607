import {
  getDaysTillDate,
  getDaysTillGoalDate,
  getEventDateWeight,
  getFasterProgressDaysDiff,
  getLocalizedDateLabel,
} from "../helpers"

export default (getView) =>
  ({ redirectIfWeightGainUrl }) => ({
    ...getView({
      redirectIfWeightGainUrl,
    }),

    eventDateLabel: null,
    eventDateWeight: null,
    eventDateWeightDiff: null,
    eventDateChartPosition: null,
    goalDateLabelTitle: null,
    goalDateLabel: null,
    initContentHidden: true,

    init() {
      this.store = Alpine.store("data")

      if (redirectIfWeightGainUrl) {
        this.redirectIfWeightGain(redirectIfWeightGainUrl)
      }

      this.eventDateWeight = this.getEventDateWeight()
      const eventDate = new Date(this.store.values.importantEventDate)
      this.eventDateLabel = getLocalizedDateLabel(eventDate)

      const isMetric = this.isMetric()
      this.eventDateWeightDiff = isMetric
        ? this.store.values.weightKg - this.eventDateWeight
        : this.store.values.weightLbs - this.eventDateWeight

      this.eventDateChartPosition = this.getEventDateChartPosition()

      this.runDatesAnimation()
    },

    isMetric() {
      return this.store.values.measurementSystem === "metric"
    },

    getEventDateChartPosition() {
      const daysTillEventDate = this.getDaysTillEventDate()
      const daysTillGoalDate = this.getDaysTillGoalDate()

      const eventDatePoint = Math.round((daysTillEventDate * 100) / daysTillGoalDate)

      if (eventDatePoint < 33) {
        return "early"
      }
      if (eventDatePoint < 66) {
        return "middle"
      }
      if (eventDatePoint < 100) {
        return "late"
      }
    },

    getEventDateWeight() {
      const isMetric = this.isMetric()

      return getEventDateWeight(this.store.values, isMetric)
    },

    getDaysTillEventDate() {
      return getDaysTillDate(this.store.values.importantEventDate)
    },

    getDaysTillGoalDate() {
      const isMetric = this.isMetric()

      return getDaysTillGoalDate(this.store.values, isMetric)
    },

    runDatesAnimation() {
      const daysTillGoalDate = this.getDaysTillGoalDate()
      const isMetric = this.isMetric()
      const minusDays = getFasterProgressDaysDiff(this.store.values, isMetric)
      let duration = 200
      let daysCount = 0

      // Start from the goal date
      const goalDate = new Date()
      goalDate.setDate(goalDate.getDate() + daysTillGoalDate)

      // Recursive updates per day
      const animationTick = () => {
        if (daysCount <= minusDays) {
          const newGoalDate = new Date(goalDate)
          newGoalDate.setDate(newGoalDate.getDate() - daysCount)

          this.goalDateLabelTitle = getLocalizedDateLabel(newGoalDate, false)
          this.goalDateLabel = getLocalizedDateLabel(newGoalDate)

          daysCount++

          // Gradually change the animation duration for nicer UI effect
          duration = duration * Math.log2(2.5)

          setTimeout(animationTick, duration)
        }
      }

      animationTick()
    },
  })
