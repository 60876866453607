export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    defaultValue: 2,

    init() {
      this.commonInit()

      this.formValues.walking = this.formValues.walking || this.defaultValue
    },

    handleInput($event, field) {
      this.formValues[field] = Number($event.target.value)
    },
  })
