import Alpine from "alpinejs"
import { load, store } from "../localStorage"
import validate from "../validations"
import pipeHeader from "./pipeHeader"
import optionsForm from "./optionsForm"
import heightForm from "./heightForm"
import weightForm from "./weightForm"
import targetWeightForm from "./targetWeightForm"
import importantEventDate from "./importantEventDate"
import timer from "./timer"
import checkout from "./checkout"
import primer from "./primer"
import goalPace from "./goalPace"
import fasterProgress from "./fasterProgress"
import walking from "./walking"
import email from "./email"
import results from "./results"
import circularLoading from "./circularLoading"
import glassesOfWater from "./glassesOfWater"
import sliderInput from "./sliderInput"
import cookieConsent from "./cookieConsent"

const VIEW_STORAGE_KEY = "view"
const GLOBAL_STORAGE_KEY = "data"

const basicView = ({ nextStepPath, formFields, redirectIfWeightGainUrl }) => ({
  isSubmitAllowed: false,
  showSpinner: false,
  formValues: {},
  errors: {},
  showFetchError: false,
  segmentActionName: "",

  initFormValues(formFields) {
    if (!formFields) return

    const viewData = load(VIEW_STORAGE_KEY)
    const globalData = load(GLOBAL_STORAGE_KEY)

    const isActiveErrors = viewData?.errors && viewData.path === window.location.pathname

    if (isActiveErrors) {
      this.formValues = viewData.formValues
      this.errors = viewData.errors
    } else {
      formFields.map((field) => {
        const value = globalData.values[field]

        this.formValues[field] = value
      })
    }

    this.setSubmitAllowed(this.formValues, formFields)
  },

  commonInit() {
    this.store = Alpine.store("data")

    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.showSpinner = false
        this.isSubmitAllowed = true
      }
    })

    this.initFormValues(formFields)

    if (redirectIfWeightGainUrl) {
      this.redirectIfWeightGain(redirectIfWeightGainUrl)
    }
  },

  init() {
    this.commonInit()
  },

  isSelected(name, value) {
    return Boolean(this.formValues[name]?.includes(value))
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  isImperial() {
    return this.store.values.measurementSystem === "imperial"
  },

  setMeasurementSystem(value) {
    if (!value) return

    this.store.values.measurementSystem = value
  },

  update(field, value) {
    this.formValues[field] = value
    this.submit()
  },

  updateAndRedirect(field, value, redirectPath) {
    this.formValues[field] = value
    this.submit({ redirectPath })
  },

  handleInput($event, field) {
    this.formValues[field] = $event.target.value && $event.target.value.trim()

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  handleNumericInput($event, field) {
    $event.target.value = $event.target.value.replace(/[^\d]/g, "")

    this.formValues[field] = $event.target.value && Number($event.target.value)

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  getRequiredFields(formFields) {
    // Treat all formFields as required by default
    if (Array.isArray(formFields)) {
      return formFields
    }

    // Provide the ability to pass the extended config for the formFields
    return Object.keys(formFields).map((key) => {
      if (formFields[key].required) {
        return key
      }
    })
  },

  setSubmitAllowed(formValues, formFields) {
    this.validateRequired(formValues, formFields)
  },

  validateRequired(formValues, formFields) {
    const requiredFields = this.getRequiredFields(formFields)
    const allRequiredPresent = requiredFields.every((value) => {
      const formValue = formValues[value]

      if (Array.isArray(formValue)) return !!formValue.length

      return formValue && formValue !== 0
    })

    this.isSubmitAllowed = allRequiredPresent
  },

  resetErrors() {
    this.errors = {}
  },

  processFormValues() {
    return this.formValues
  },

  storeViewData(data) {
    store(VIEW_STORAGE_KEY, {
      path: window.location.pathname,
      ...data,
    })
  },

  async submit({ push, redirectPath } = {}) {
    const nextPath = redirectPath || nextStepPath
    const formValues = this.processFormValues()

    const { hasErrors, errors } = validate(formValues, this.store)

    if (hasErrors) {
      window.analytics.track(window.getEventName("Invalid Form Submitted"))

      this.storeViewData({ errors, formValues: this.formValues })

      this.errors = { ...this.errors, ...errors }
    } else {
      this.showSpinner = true
      this.isSubmitAllowed = false

      this.storeViewData(null)
      this.store.values = { ...this.store.values, ...formValues }

      if (!push || (await this.store.push())) {
        window.location = nextPath
      } else {
        if (this.store.emailValidationError) {
          this.store.values.email = ""
          this.errors.email = true
        }

        this.showFetchError = true
        this.showSpinner = false
      }
    }
  },

  // The edge case handling, when user's target weight is bigger than the current weight
  redirectIfWeightGain(url) {
    const isMetric = this.isMetric()

    if (isMetric) {
      if (this.store.values.targetWeightKg >= this.store.values.weightKg) {
        window.location = url
        return
      }
    } else {
      if (this.store.values.targetWeightLbs >= this.store.values.weightLbs) {
        window.location = url
        return
      }
    }

    // The component's content may be hidden before init for the better UI
    this.initContentHidden = false
  },
})

export default [
  { name: "view", data: basicView },
  { name: "cookieConsent", data: cookieConsent },
  { name: "pipeHeader", data: pipeHeader },
  { name: "optionsForm", data: optionsForm(basicView) },
  { name: "heightForm", data: heightForm(basicView) },
  { name: "weightForm", data: weightForm(basicView) },
  { name: "targetWeightForm", data: targetWeightForm(basicView) },
  { name: "goalPace", data: goalPace(basicView) },
  { name: "fasterProgress", data: fasterProgress(basicView) },
  { name: "walking", data: walking(basicView) },
  { name: "glassesOfWater", data: glassesOfWater(basicView) },
  { name: "importantEventDate", data: importantEventDate(basicView) },
  { name: "email", data: email(basicView) },
  { name: "sliderInput", data: sliderInput },
  { name: "circularLoading", data: circularLoading },
  { name: "results", data: results },
  { name: "timer", data: timer },
  { name: "checkout", data: checkout },
  { name: "primer", data: primer },
]
