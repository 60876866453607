const getDaysTillGoalDate = (values, isMetric) => {
  const { weightKg, weightLbs, targetWeightKg, targetWeightLbs, goalPaceKg, goalPaceLbs } = values

  // Goal data calculation provided by the product team:
  // Days until goal reached = (Current weight – Goal weight) / x * 7
  // where x = the kg/week (0,25 | 0,5 | 0,75 | 1)
  // Example:
  // (98-78)/0,75 * 7 = 189 days until goal is reached

  let daysTillGoalDate

  if (isMetric) {
    const weightDiff = weightKg - targetWeightKg
    daysTillGoalDate = (weightDiff / goalPaceKg) * 7
  } else {
    const weightDiff = weightLbs - targetWeightLbs
    daysTillGoalDate = (weightDiff / goalPaceLbs) * 7
  }

  return daysTillGoalDate
}

const getDaysTillDate = (eventDate) => {
  if (!eventDate) return 0

  const today = new Date()
  const date = new Date(eventDate)

  const timeDiff = date.getTime() - today.getTime()

  return Math.round(timeDiff / (1000 * 3600 * 24))
}

const getFasterProgressDaysDiff = (values, isMetric) => {
  const { importantEventDate } = values

  const daysTillEventDate = getDaysTillDate(importantEventDate)
  const daysTillGoalDate = getDaysTillGoalDate(values, isMetric)

  // Product requirement:
  // Enable faster progress date only if
  // certain days thresholds presented
  const threshold = daysTillEventDate ? 10 : 35
  const isEnabled = daysTillEventDate
    ? daysTillGoalDate - daysTillEventDate >= threshold
    : daysTillGoalDate >= threshold

  return isEnabled ? 5 : 0
}

const getEventDateWeight = (values, isMetric) => {
  const { importantEventDate, weightKg, weightLbs, goalPaceKg, goalPaceLbs } = values

  // Goal data calculation provided by the product team:
  // eventDateWeight = (weightKg - daysTillEventDate) / goalPaceKg * 7

  let eventDateWeight
  const daysTillEventDate = getDaysTillDate(importantEventDate)

  if (isMetric) {
    eventDateWeight = weightKg - (daysTillEventDate / 7) * goalPaceKg
  } else {
    eventDateWeight = weightLbs - (daysTillEventDate / 7) * goalPaceLbs
  }

  return Math.round(eventDateWeight)
}

const getLocalizedDateLabel = (date, isIncludeYear = true) => {
  const options = { day: "numeric", month: "short" }

  if (isIncludeYear) {
    options.year = "numeric"
  }

  const locale = pipelineConfig.language || "en"
  const localizedDateLabel = date.toLocaleDateString(locale, options)

  return localizedDateLabel
}

const getCompactLocalizedDateLabel = (date) => {
  const options = { month: "short", year: "numeric" }
  const locale = pipelineConfig.language || "en"
  const compactLocalizedDateLabel = date.toLocaleDateString(locale, options).replace(/,/g, "")

  return compactLocalizedDateLabel
}

const getInputDateFormatted = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

//
// BMI
//
const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

const getNormalizedHeight = (values, isMetric) => {
  const { heightCm, heightFt, heightIn } = values

  if (isMetric) {
    return heightCm
  } else {
    return heightFt * 30.48 + heightIn * 2.54
  }
}

const getNormalizedWeight = (values, isMetric) => {
  const { weightKg, weightLbs } = values

  if (isMetric) {
    return weightKg
  } else {
    return weightLbs * KGS_IN_LBS
  }
}

const getBmi = (values, isMetric) => {
  const weight = getNormalizedWeight(values, isMetric)
  const height = getNormalizedHeight(values, isMetric)
  return weight / (height / 100.0) ** 2
}

const getBmiPercents = (bmi) => {
  const percents = ((bmi - 12.01) * 100) / (29.99 - 12.01)

  if (percents > 98) {
    return 98
  } else if (percents < 0) {
    return 0
  }

  return percents.toFixed()
}

const getGolaPaceKgValues = () => [0.5, 1, 2]
const getDefaultGoalPaceKg = () => 1
const getGolaPaceLbsValues = () => [1, 2.5, 4.5]
const getDefaultGoalPaceLbs = () => 2.5

export {
  getLocalizedDateLabel,
  getCompactLocalizedDateLabel,
  getDaysTillDate,
  getDaysTillGoalDate,
  getInputDateFormatted,
  getEventDateWeight,
  getNormalizedHeight,
  getNormalizedWeight,
  getBmi,
  getBmiPercents,
  getGolaPaceKgValues,
  getDefaultGoalPaceKg,
  getGolaPaceLbsValues,
  getDefaultGoalPaceLbs,
  getFasterProgressDaysDiff,
}
