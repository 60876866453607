import { load, store, getStoreByKeyValue } from "./localStorage"
import Bugsnag from "@bugsnag/js"
import smoothscroll from "smoothscroll-polyfill"

const LOCAL_STORAGE_KEY = "data"
const INVALID_DOMAIN_CODE = "invalid_domain"
const EMAIL_VALIDATION_ERROR = "email: Invalid email"
const EMAIL_VALIDATION_CODE = "email_verification_failed"

function initialState() {
  return {
    // Globals
    token: null,
    measurementSystem: pipelineConfig.measurementSystem,
    domain: null,
    cookies: false,
    gender: null,
    ageRange: null,
    firstName: null,
    yourGoal: null,
    bodyType: null,
    familiarWithFasting: null,
    hungerTime: null,
    breakfastTime: null,
    lunchTime: null,
    dinnerTime: null,
    mealPrep: null,
    fastingWeekends: null,
    physicallyActive: null,
    workingHours: null,
    describeJob: null,
    willToLoseWeight: null,
    age: null,
    heightFt: null,
    heightIn: 0,
    heightCm: null,
    weightKg: null,
    weightLbs: null,
    targetWeightKg: null,
    targetWeightLbs: null,
    goalPaceKg: null,
    goalPaceLbs: null,
    walking: null,
    sleep: null,
    diets: null,
    glassesOfWater: null,
    importantEvent: null,
    importantEventDate: null,
    medication: null,
    deseases: null,
    email: null,
    marketingAgreed: null,
    segmentUserId: null,
  }
}

export default {
  // Push data store to backend API
  init() {
    const data = load(LOCAL_STORAGE_KEY)

    if (data && data.values) {
      this.values = data.values
    } else {
      this.values = initialState()
    }

    if (!this.values?.activeOffer) {
      const pipelineOffers = pipelineConfig.offers
      const defaultOffer = pipelineOffers.find(
        (offer) => offer.customData.default_select === "true"
      )

      this.values.activeOffer = defaultOffer || pipelineOffers[0]
    }

    this.storeAnalytics()

    Alpine.effect(() => {
      store(LOCAL_STORAGE_KEY, { values: this.values })
    })
  },

  storeAnalytics() {
    const analyticsData = {}

    analyticsData.domain = document.location.host

    // Add cookies - _ga, _gid, _fbc, _fbp, ...
    document.cookie.split("; ").forEach((item) => {
      const [key, value] = item.split("=")
      analyticsData[key] = value
    })

    // Add search params - fbclid, utm_*, ...
    const url = new URL(document.location)
    url.searchParams.forEach((value, key) => {
      analyticsData[key] = value
    })

    // Add FraudNet data
    analyticsData["fraud_net_session_id"] = localStorage.getItem("fraud_net_session_id") || ""

    this.values = { ...this.values, ...analyticsData }
  },

  writeToLocalStorage(data) {
    window.localStorage.setItem(
      `${pipelineConfig.pathPrefix}:${LOCAL_STORAGE_KEY}`,
      JSON.stringify({ values: data })
    )
  },

  getFromLocalStorage(key) {
    const val = getStoreByKeyValue(LOCAL_STORAGE_KEY, key)
    return typeof val === "string" ? val : null
  },

  async pull() {
    if (this.token) {
      const res = await fetch(pipelineConfig.orderApiUrl, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "x-token": this.token,
        },
      })
      const data = await res.json()

      Object.entries(data).forEach(([key, value]) => {
        this[key] = value
      })

      await this.identifySegmentUser(this.segmentUserId, this.email)

      this.writeToLocalStorage({
        ...data,
      })
    }
  },

  // Push data store to backend API
  async push() {
    const apiUrl = pipelineConfig.orderApiUrl
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(this.values),
    }

    if (this.values.token) {
      options.method = "PATCH"
      options.headers["x-token"] = this.values.token
    }

    try {
      this.globalError = null
      this.emailValidationError = null

      const res = await fetch(apiUrl, options)
      const data = await res.json()

      if (res.ok) {
        this.globalError = null
        this.emailValidationError = null

        Object.entries(data).forEach(([key, value]) => {
          this.values[key] = value
        })
        await this.identifySegmentUser(this.values.segmentUserId, this.values.email)
      } else {
        if (
          data.code === INVALID_DOMAIN_CODE ||
          data.error === EMAIL_VALIDATION_ERROR ||
          data.code === EMAIL_VALIDATION_CODE
        ) {
          this.emailValidationError = data
        } else {
          this.globalError = data.error
        }
      }
    } catch (error) {
      this.globalError = error
      Bugsnag.notify(error)
    }

    return !this.globalError && !this.emailValidationError
  },

  get isMetric() {
    return this.values.measurementSystem === "metric"
  },

  get isImperial() {
    return this.values.measurementSystem === "imperial"
  },

  goToLogin() {
    const loginUrl = this.values.loginUrl
    setTimeout(function () {
      this.values = initialState()
      window.location = loginUrl || pipelineConfig.loginUrl
    }, 1000)
  },

  resetLocalStorage() {
    this.values = initialState()
    this.storeAnalytics()
  },

  async identifySegmentUser(segmentUserId, email) {
    if (!segmentUserId) {
      return
    }
    if (window.localStorage.getItem("ajs_user_id") !== segmentUserId) {
      window.localStorage.setItem("ajs_user_id", segmentUserId)
      await window.analytics.identify(segmentUserId, { email })
    }
  },
}
