const progressStepsPaths = [
  { path: "/01-step/" },
  { path: "/02-step/" },
  { path: "/03-step/" },
  { path: "/04-step/" },
  { path: "/05-step/", skipCount: true },
  { path: "/06-step/" },
  { path: "/07-step/" },
  { path: "/08-step/" },
  { path: "/09-step/" },
  { path: "/10-step/" },
  { path: "/11-step/" },
  { path: "/12-step/" },
  { path: "/13-step/", skipCount: true },
  { path: "/14-step/" },
  { path: "/15-step/" },
  { path: "/16-step/" },
  { path: "/17-step/" },
  { path: "/18-step/" },
  { path: "/19-step/" },
  { path: "/20-step/" },
  { path: "/21-step/" },
  { path: "/22-step/", skipCount: true },
  { path: "/23-step/" },
  { path: "/24-step/" },
  { path: "/25-step/" },
  { path: "/26-step/" },
  { path: "/27-step/", skipCount: true },
  { path: "/28-step/", skipCount: true },
  { path: "/29-step/", skipCount: true },
  { path: "/30-step/" },
  { path: "/31-step/" },
  { path: "/32-step/" },
]

export default (activeStepPath) => ({
  progressWidth: null,
  progressStep: null,
  totalSteps: null,

  init() {
    this.progressStep = this.getProgressStep(activeStepPath)
    this.totalSteps = this.getTotalSteps()
    this.progressWidth = this.getProgressWidth()
  },

  getProgressStep(activeStepPath) {
    const currentStepIndex = progressStepsPaths.findIndex((step) => step.path === activeStepPath)

    return progressStepsPaths
      .slice(0, currentStepIndex + 1) // Get all previous steps including the current one
      .filter((step) => !step.skipCount).length // Filter out the steps that should not be counted
  },

  getTotalSteps() {
    return progressStepsPaths.filter((step) => !step.skipCount).length // Filter out the steps that should not be counted
  },

  getProgressWidth() {
    return (this.progressStep * 100) / this.totalSteps
  },
})
