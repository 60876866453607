import {
  getDaysTillGoalDate,
  getCompactLocalizedDateLabel,
  getBmi,
  getGolaPaceKgValues,
  getGolaPaceLbsValues,
} from "../helpers"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462
export const kgToLbs = (kg) => kg / KGS_IN_LBS
export const lbsToKg = (lbs) => lbs / LBS_IN_KG

export default () => ({
  isLoading: true,

  init() {
    this.store = Alpine.store("data")
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  getCurrentMonthLabel() {
    const today = new Date()
    return getCompactLocalizedDateLabel(today)
  },

  getGoalMonthLabel() {
    const isMetric = this.isMetric()
    const { weightKg, weightLbs, targetWeightKg, targetWeightLbs, goalPaceKg, goalPaceLbs } =
      this.store.values

    const daysTillGoalDate = getDaysTillGoalDate(
      {
        weightKg,
        weightLbs,
        targetWeightKg,
        targetWeightLbs,
        goalPaceKg,
        goalPaceLbs,
      },
      isMetric
    )

    const goalDate = new Date()
    goalDate.setDate(goalDate.getDate() + daysTillGoalDate)

    return getCompactLocalizedDateLabel(goalDate)
  },

  getBmi() {
    return getBmi(this.store.values, this.isMetric()).toFixed(2)
  },

  getBmiWidgetPointPosition() {
    const bmi = this.getBmi()

    const scaleMin = 15
    const scaleMax = 35

    if (bmi <= scaleMin) return 0
    if (bmi >= scaleMax) return 100

    return ((bmi - scaleMin) * 100) / (scaleMax - scaleMin)
  },

  getBmr() {
    if (this.store.values.gender === "male") {
      return (
        66.47 +
        13.75 * this.normalizedWeight() +
        5 * this.normalizedHeight() -
        6.76 * this.store.values.age
      )
    }

    return (
      655.1 +
      9.56 * this.normalizedWeight() +
      1.85 * this.normalizedHeight() -
      4.68 * this.store.values.age
    )
  },

  getOptimalKcal() {
    const kcal = this.getBmr() * 1.2 - 300

    return `${Math.round(kcal - 75)} – ${Math.round(kcal + 75)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )
  },

  getMaximumKcal() {
    const kcal = this.getBmr() * 1.2 - 500

    return `${Math.round(kcal - 120)} – ${Math.round(kcal + 120)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    )
  },

  // Product requirement for the formula:
  // 4 x maximum goal pace (2kg | 4.5lbs per week)
  getFirstMonthWeightLoss() {
    const maxGoalPace = this.isMetric()
      ? getGolaPaceKgValues().reverse()[0]
      : getGolaPaceLbsValues().reverse()[0]

    return Math.round(4 * maxGoalPace)
  },

  // Product requirement for the formula:
  // Use maximum goal pace (2kg | 4.5lbs per week)
  getFirstWeekValue() {
    const maxGoalPace = this.isMetric()
      ? getGolaPaceKgValues().reverse()[0]
      : getGolaPaceLbsValues().reverse()[0]

    return Math.round(maxGoalPace)
  },

  normalizedHeight() {
    if (this.isMetric()) {
      return this.store.values.heightCm
    } else {
      return this.store.values.heightFt * 30.48 + this.store.values.heightIn * 2.54
    }
  },

  idealWeight() {
    return Math.round((this.normalizedHeight() * 2 * 21.1) / 10000)
  },

  normalizedWeight() {
    if (this.isMetric()) {
      return this.store.values.weightKg
    } else {
      return this.store.values.weightLbs * KGS_IN_LBS
    }
  },

  overWeight() {
    return this.normalizedWeight() - this.idealWeight()
  },

  firstMonth() {
    let val = (30 / 100) * this.overWeight()

    if (val > 7) {
      return 7
    } else if (val < 4) {
      return 4
    }

    return Math.floor(val)
  },
})
