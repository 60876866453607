export default ({ duration, id }) => ({
  timeLeft: null,
  mm: null,
  ss: null,

  init() {
    this.timeLeft = duration
    this.mm = document.getElementById(`${id}-mm`)
    this.ss = document.getElementById(`${id}-ss`)

    this.updateTimer()

    setInterval(() => {
      this.updateTimer()
    }, 1000)
  },

  updateTimer() {
    minutes = parseInt(this.timeLeft / 60, 10)
    seconds = parseInt(this.timeLeft % 60, 10)

    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    this.mm.textContent = minutes
    // this.ss.textContent = seconds
    this.ss.innerHTML = `<span class="pipe-timer-delimiter">:</span>${seconds}`

    if (--this.timeLeft < 0) {
      this.timeLeft = duration
    }
  },
})
