export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    defaultValue: 0,

    init() {
      this.commonInit()

      this.formValues.glassesOfWater = this.formValues.glassesOfWater || this.defaultValue
    },

    handleClick(number) {
      this.formValues.glassesOfWater = number
      this.setSubmitAllowed(this.formValues, formFields)
    },
  })
