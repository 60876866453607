function setRafInterval(callback, delay) {
  let start = Date.now()
  let stop = false

  const intervalFunc = function () {
    const delta = Date.now() - start

    if (delta >= delay) {
      start += delay
      callback()
    }

    stop || requestAnimationFrame(intervalFunc)
  }

  requestAnimationFrame(intervalFunc)

  return {
    clear: function () {
      stop = true
    },
  }
}

function generateRandomProgress(stepsQty) {
  const minStepValue = 1
  const maxStepValue = 10
  const steps = []
  let remainingProgress = 100

  for (let i = 0; i < stepsQty - 1; i++) {
    // Ensure remainingProgress is sufficient for the remaining steps
    const maxAllowedValue = Math.min(maxStepValue, Math.floor(remainingProgress / (stepsQty - i)))
    const step = Math.floor(Math.random() * (maxAllowedValue - minStepValue + 1)) + minStepValue
    steps.push(step)
    remainingProgress -= step
  }

  steps.push(remainingProgress > 0 ? remainingProgress : 0)

  return steps
}

export default ({ d, fillWidth, onEnd }) => ({
  isLoading: true,
  percents: 0,
  step: 0,
  d: d,
  fillWidth: fillWidth,
  circumference: (d - fillWidth) * 3.14,
  strokeDasharray: `0 0`,

  init() {
    this.strokeDasharray = `0 ${this.circumference}`
    this.renderLoadingAnimation()
  },

  renderLoadingAnimation() {
    const stepsQty = 20
    const animationDuration = 10000
    const animationInterval = animationDuration / stepsQty

    const progressSteps = generateRandomProgress(stepsQty)
    let iterations = progressSteps.length
    let step = 0

    const animation = setRafInterval(() => {
      if (step >= iterations) {
        animation.clear()

        // Add extra step to show the post-animation message
        step++
        this.step = step
        this.isLoading = false

        onEnd()

        return
      }

      const percents = this.percents + progressSteps[step]

      const fillWidth = Math.floor((this.circumference / 100) * percents)
      const bgWidth = Math.floor(this.circumference - fillWidth)
      // SVG attribute value to render progress fill
      this.strokeDasharray = `${fillWidth} ${bgWidth}`

      step++
      this.step = step

      this.percents = percents
    }, animationInterval)
  },
})
