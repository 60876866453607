const colorActive = "#29ABE2"
const colorIdle = "#AEDFF4"

export default ({ containerId, id, step }) => ({
  inputEl: null,
  textDirection: pipelineConfig.dir,

  init() {
    this.container = document.getElementById(containerId)
    this.inputEl = document.getElementById(id)
    // Re-render progress UI on the next tick
    // due to Alpine JS initial input value handling
    setTimeout(() => this.renderProgress())
  },

  handleChange($event, callback) {
    window.analytics.track(window.getEventName("Selected"), {
      value: $event.target.value,
    })

    this.renderProgress()
    callback($event)
  },

  renderProgress() {
    // Zero-based, that's why subtract `min`
    const min = Number(this.inputEl.min)
    const value = Number(this.inputEl.value) - min
    const max = Number(this.inputEl.max) - min
    const progress = (value / max) * 100

    const tickCount = Math.floor(max / Number(step))
    const tickIntervalPercent = 100 / tickCount

    // Remove existing ticks
    const existingTicks = this.container.querySelectorAll(".pipe-slider-input-tick")
    existingTicks.forEach((tick) => tick.remove())

    // Render ticks
    for (let i = 0; i <= tickCount; i++) {
      if (value !== i) {
        const background = i <= value ? colorActive : colorIdle
        const tick = document.createElement("div")

        tick.className = "pipe-slider-input-tick"

        if (this.textDirection.toLowerCase() === "rtl") {
          tick.style.right = i * tickIntervalPercent + "%"
        } else {
          tick.style.left = i * tickIntervalPercent + "%"
        }

        tick.style.background = background
        this.container.appendChild(tick)
      }
    }

    if (this.textDirection.toLowerCase() === "rtl") {
      this.inputEl.style.background = `linear-gradient(to left, ${colorActive} ${progress}%, ${colorIdle} ${progress}%)`
    } else {
      this.inputEl.style.background = `linear-gradient(to right, ${colorActive} ${progress}%, ${colorIdle} ${progress}%)`
    }
  },
})
